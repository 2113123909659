import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import { Box } from '../components/common/Box';
import { Grid } from '../components/common/Grid';
import { H1 } from '../components/common/Heading';
import { InnerGrid } from '../components/common/InnerGrid';
import { LineHeading } from '../components/common/LineHeading';
import { Text } from '../components/common/Text';
import { ZoomImage } from '../components/common/ZoomImage';
import { Layout } from '../components/Layout';
import { AnimatedBox, springAnimationWithDelay2 } from '../components/sections/grid/gridHelpers';
import { SectionRenderer } from '../components/sections/SectionRenderer';
import { Seo } from '../components/Seo';
import { useInViewSpring } from '../hooks/useInViewSpring';
import { color } from '../theme/color';
import { bodyPadding, grid } from '../theme/theme';
import { addURLPrefix } from '../utils/addURLPrefix';
import { extractWithLocale } from '../utils/extractWithLocale';
import { getSlugsFromPage } from '../utils/getSlugsFromPage';
import { isNotNull } from '../utils/isNotNull';
import { PageProps } from '../utils/PageProps';
import { combineFullSections } from '../utils/rawSectionHelpers';
import { CaseQuery } from '../__generated__/graphql';
import { isIE } from 'react-device-detect';
import BrowserNotSupported from '../components/BrowserNotSupported';
import { getFixedGifSrc } from '../utils/getFixedGifSrc';
import { TalksProvider } from '../components/TalksProvider';
import { useThemeContext } from '../components/ThemeChanger';

const Case: React.FC<PageProps<CaseQuery>> = ({ data, pageContext, location }) => {
  const [notSupportedBrowser, setNotSupportedBrowser] = useState(false);
  const themeCtx = useThemeContext();
  useEffect(() => {
    setNotSupportedBrowser(isIE);
    themeCtx.setTheme('light');
  }, []);

  const page = data.sanityCase!;
  const nextPage = data.nextCase!;
  const locale = pageContext.locale;
  const seoImage = page.seo?.seoImage?.asset?.gatsbyImageData?.images?.fallback?.src;
  const seoDescription = page.seo && extractWithLocale(page.seo.description, pageContext.locale);

  if (!page) return null;

  const slugs = getSlugsFromPage(page, 'cases');

  const name = extractWithLocale(page.name, locale) ?? '';
  const heading = extractWithLocale(page.heading, locale);
  const preamble = extractWithLocale(page.preamble, locale);

  const nextHeading = extractWithLocale(nextPage.heading, locale);
  const nextPreamble = extractWithLocale(nextPage.preamble, locale);
  const nextLink = addURLPrefix(
    extractWithLocale(nextPage.slug, locale)?.current ?? '',
    'case',
    locale
  );

  const sections = page.sections?.filter(isNotNull) ?? [];
  const rawSections = page._rawSections?.filter(isNotNull) ?? [];
  const fullSections = combineFullSections(sections, rawSections);

  const fluidImage = getFixedGifSrc(page?.image?.asset?.gatsbyImageData);
  const nextPageFluidImage = getFixedGifSrc(nextPage?.image?.asset?.gatsbyImageData);

  return notSupportedBrowser ? (
    <Layout
      locale={pageContext.locale}
      alternativeLinks={slugs}
      overlayHeaderOverContent={true}
      location={location}
      showFooter={false}
    >
      <Seo title={name} description={seoDescription || preamble || ''} location={location} />
      <Grid gridTemplateColumns={grid} data-component="wut">
        <BrowserNotSupported locale={pageContext.locale} />
      </Grid>
    </Layout>
  ) : (
    <TalksProvider show={page.showEsattoTalksBubble}>
      <Layout locale={locale} location={location} alternativeLinks={slugs} showFooter={true}>
        <Seo
          title={name}
          description={seoDescription || preamble || ''}
          image={seoImage ? { url: seoImage, width: 1200, height: 630 } : null}
          location={location}
        />
        <Grid gridTemplateColumns={grid}>
          <CaseHeader
            heading={heading}
            clientName={page.client?.name?.toUpperCase()}
            preamble={preamble}
            fluidImage={fluidImage}
          />

          <SectionRenderer sections={fullSections} />

          <Box gridColumn="2 / -2" mt={4} mb={6}>
            <Link
              to={nextLink}
              style={{ textDecoration: 'none' }}
              title={locale === 'sv' ? 'Nästa projekt' : 'Next project'}
            >
              <NextCaseHeader
                heading={nextHeading}
                clientName={nextPage?.client?.name?.toUpperCase()}
                preamble={nextPreamble}
                fluidImage={nextPageFluidImage}
                mb={0}
                height={['20vh', '30vh']}
                showPreambleOnMobile={false}
                locale={locale}
              />
            </Link>
          </Box>
        </Grid>
      </Layout>
    </TalksProvider>
  );
};

type CaseHeaderProps = {
  heading?: string | null;
  clientName?: string;
  preamble?: string | null;
  fluidImage?: any;
  showPreambleOnMobile?: boolean;
  mb?: number | number[];
  height?: string | number | Array<string | number>;
  locale: 'sv' | 'en' | 'dk';
};

const CaseHeader: React.FC<CaseHeaderProps> = ({
  heading,
  clientName,
  preamble,
  fluidImage,
  showPreambleOnMobile = true,
  mb = 4,
  height = 'auto',
  locale,
}) => {
  const [ref, springProps] = useInViewSpring({ spring: springAnimationWithDelay2, index: 0 });

  return (
    <InnerGrid mb={mb} mt={4}>
      <Box gridColumn={['span 4', 'span 2']} px={bodyPadding} maxWidth="90%">
        <H1 fontSize={[5, 6]} fontWeight={900} lineHeight={1.1}>
          {heading}
        </H1>

        <Text fontSize={2} fontWeight={300} letterSpacing="0.15em">
          {clientName}
        </Text>
      </Box>
      <Box
        gridColumn={['span 4', 'span 2']}
        px={bodyPadding}
        display={showPreambleOnMobile ? 'block' : ['none', 'block']}
      >
        <Text fontSize={[2, 3]} fontWeight={300}>
          {preamble}
        </Text>
      </Box>
      <AnimatedBox style={springProps} gridColumn="1 / -1" mt={5} overflow="hidden" height={height}>
        <div ref={ref}>
          {fluidImage && <GatsbyImage image={{ ...fluidImage, aspectRatio: 16 / 6 }} />}
        </div>
      </AnimatedBox>
    </InnerGrid>
  );
};

//  TODO: look to combine with CaseHeader
const NextCaseHeader: React.FC<CaseHeaderProps> = ({
  heading,
  clientName,
  preamble,
  fluidImage,
  showPreambleOnMobile = true,
  mb = 4,
  height = 'auto',
  locale,
}) => {
  const [ref, springProps] = useInViewSpring({ spring: springAnimationWithDelay2, index: 0 });

  return (
    <ZoomImage>
      <div className="zoom-activate">
        <Box gridColumn={'2 / -2'} px={bodyPadding}>
          <LineHeading fontWeight={400} fontSize="18px" color={color.orange}>
            {locale === 'sv' ? 'Nästa projekt' : 'Next project'}
          </LineHeading>
        </Box>
        <InnerGrid mb={mb} mt={4}>
          <Box gridColumn={['span 4', 'span 2']} px={bodyPadding} maxWidth="90%">
            <H1 fontSize={5} lineHeight={1.3}>
              {heading}
            </H1>

            <Text fontSize={2} fontWeight={500} letterSpacing="0.15em">
              {clientName}
            </Text>
          </Box>
          <Box
            gridColumn={['span 4', 'span 2']}
            px={bodyPadding}
            display={showPreambleOnMobile ? 'block' : ['none', 'block']}
          >
            <Text fontSize={3} fontWeight={300}>
              {preamble}
            </Text>
          </Box>
          <AnimatedBox
            style={springProps}
            gridColumn="1 / -1"
            mt={5}
            overflow="hidden"
            height={height}
          >
            <div className="zoom-wrapper" ref={ref}>
              {fluidImage && <GatsbyImage image={fluidImage} className="zoom-img" />}
            </div>
          </AnimatedBox>
        </InnerGrid>
      </div>
    </ZoomImage>
  );
};

export const query = graphql`
  query CaseQuery($id: String!, $nextId: String!) {
    sanityCase(_id: { eq: $id }) {
      name {
        sv
        en
        dk
      }
      slug {
        en {
          current
        }
        sv {
          current
        }
        dk {
          current
        }
      }
      seo {
        title {
          sv
          en
          dk
        }
        description {
          sv
          en
          dk
        }
        clearTemplate
        follow
        index
        seoImage: image {
          asset {
            gatsbyImageData(layout: FIXED, width: 1200, height: 630)
          }
        }
      }
      client {
        name
      }
      showEsattoTalksBubble
      heading {
        sv
        en
        dk
      }
      preamble {
        en
        dk
        sv
      }
      image {
        asset {
          gatsbyImageData
        }
      }
      imageAltText {
        sv
        en
        dk
      }
      _rawSections
      sections {
        ...Sections
      }
    }

    nextCase: sanityCase(_id: { eq: $nextId }) {
      slug {
        en {
          current
        }
        sv {
          current
        }
        dk {
          current
        }
      }
      client {
        name
      }
      heading {
        sv
        en
        dk
      }
      preamble {
        en
        dk
        sv
      }
      imageAltText {
        sv
        en
        dk
      }
      image {
        asset {
          gatsbyImageData
        }
      }
    }
  }
`;

export default Case;
